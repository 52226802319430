<template>
  <Applications />
</template>
<script>
import { defineComponent } from "vue";
import Applications from "@/containers/Applications/Applications";
export default defineComponent({
  components: {
    Applications,
  },
  setup() {
    return {};
  },
});
</script>
