<template>
  <div style="display: flex; padding: 50px; flex-direction: column;">
    <h1>Termos e condições</h1>
    <div style="display: flex; width: 100%; height: 80%; margin-bottom: 20px;">
      <iframe
        src="https://app.travelfybrasil.com/terms"
        width="100%"
        height="1000"
        frameborder="0"
        style="border: none;"
      ></iframe>
    </div>
    <div style="margin-bottom: 20px;">
      <label>
        <input
          type="checkbox"
          v-model="isTermsAccepted"
        />
        Eu li e aceito os termos e condições.
      </label>
    </div>
    <a-button
      type="primary"
      block
      size="large"
        @click="handleAccept"
      html-type="submit"
      :loading="isLoading"
      style="background: linear-gradient(270deg,
      rgba(63, 161, 255, 1) 0%,
      rgba(103, 180, 255, 1) 77%,
      rgba(144, 200, 255, 1) 100%); border-color: rgba(144, 200, 255, 1); font-weight: bold; font-size: 16px; width: 150px;"
    >
      Aceitar
    </a-button>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { mapActions } from "vuex";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";

export default {
  name: "Terms",
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.getUser);
    const isTermsAccepted = ref(false);

    return { user, router, isTermsAccepted };
  },
  methods: {
    ...mapActions("user", [
      "verifyWelcomeUser",
      "alterWelcomeUser",
      "verifyTermsUser",
      "alterTermsUser",
    ]),

    async handleAccept() {
      if (!this.isTermsAccepted) {
        this.showWarningModal();
        return;
      }
      await this.alterTermsUser(this.user.id);
      this.$router.push("/applications");
    },
    showWarningModal() {
      if (!this.adminMode) {
        Modal.warning({
          title: "Atenção",
          content:
            "Precisa aceitar o termos para dar continuidade!",
        });
      }
    },
  },
};
</script>