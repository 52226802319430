<template>
  <a-col :span="24">
    <div style="margin-bottom: 15px">
      <a-tooltip
        :title="!isSystemEnable ? APPLICATION_WITHOUT_SUBSCRIPTION : ''"
        color="#f50"
      >
        <a-button
          v-if="!isSystemEnable"
          :class="$style.createButton"
          type="primary"
          size="large"
          shape="rounded"
          block
          :disabled="!isSystemEnable"
          href="/applications/add"
          style="display: block !important"
        >
          <template #icon>
            <PlusOutlined />
          </template>
          Preencher meu formulário
        </a-button>
        <a-button
          v-else
          :class="$style.createButton"
          type="primary"
          size="large"
          shape="rounded"
          block
          href="/applications/add"
        >
          <template #icon>
            <PlusOutlined />
          </template>
          Preencher meu formulário
        </a-button>
      </a-tooltip>
    </div>
  </a-col>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapGetters } from "vuex";
import { APPLICATION_WITHOUT_SUBSCRIPTION } from "@/utils/tooltipConstants";
export default {
  name: "NewProcessButton",
  components: {
    PlusOutlined,
  },
  data() {
    return { APPLICATION_WITHOUT_SUBSCRIPTION };
  },
  computed: {
    ...mapGetters("system", ["isSystemEnable"]),
  },
};
</script>

<style module src="./style.scss" lang="scss"></style>
