<template>
  <div :class="$style.content">
    <div :class="$style.processTitle">
      Últimas aplicações
      <a-tooltip placement="top" :title="SAVED_PROCESS">
        <a-button size="small" type="link" style="color: #fff">
          <template #icon><QuestionCircleOutlined /></template>
        </a-button>
      </a-tooltip>
    </div>
    <a-input-search
      v-model:value="formState.value"
      placeholder="Buscar por..."
      :loading="isSavedLoading"
      style="width: 100%"
      :class="$style.filter"
      @search="handleSearch"
    />
    <a-list
      item-layout="horizontal"
      :data-source="savedApplications"
      :loading="isSavedLoading"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta :description="'Criado em: ' + item.createdAt">
            <template #title>
              <a :href="`/applications/${item.id}`">{{ item.name }}</a>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <div :class="$style.pagination">
      <a-pagination
        v-if="hasItem"
        @change="handleChange"
        size="small"
        :total="savedPagination.totalItems"
        :page-size="savedPagination.itemsPerPage"
        :current="savedPagination.currentPage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { reactive } from "vue";
import { SAVED_PROCESS } from "@/utils/tooltipConstants";
export default {
  name: "RecentProcess",
  components: { QuestionCircleOutlined },
  data() {
    const formState = reactive({
      value: "",
    });

    return {
      formState,
      SAVED_PROCESS,
    };
  },
  methods: {
    ...mapActions("application", ["getSavedApplications"]),
    async handleSearch(val) {
      const data = {
        where: {
          name: val,
        },
      };
      await this.getSavedApplications(data);
    },

    async handleChange(page) {
      const data = {
        page,
        where: {
          name: this.formState.value,
        },
      };
      await this.getSavedApplications(data);
    },
    // generateUrl(item) {
    //   const url = `/applications/${item.id}`;
    //   this.$router.push(url);
    // },
  },
  computed: {
    ...mapState("application", [
      "isSavedLoading",
      "savedApplications",
      "savedPagination",
    ]),
    hasItem() {
      return this.savedApplications.length > 0;
    },
  },
  async mounted() {
    try {
      await this.getSavedApplications();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style module src="./style.scss" lang="scss"></style>
